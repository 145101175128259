#pifyform-7383 .form-wrap .form-control {
    color: ;
    border: 1px solid #9ce6e2;
    background-color: white;
    color: #42406d;
    max-width: 100%;
    line-height: 1.2;
    border-radius: 2px !important;
    height: 40px !important;
}
.form-footer .btn-submit {
    margin: 0 auto !important;
    -webkit-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: auto;
    border: none !important;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 2px;
    padding: 8px 15px;
    background-color: #9ce6e2 !important;
    color: #42406d;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    white-space: normal;
    font-size: 16px;
}
#form-wrapper {
    border: none;
}
.form-header {
    background-image: none !important;
}
.form-attributes textarea.form-control {
    border-radius: 4px;
    min-height: 200px;
    resize: none;
}
label.attribute-title {
    font-size: 18px;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    color: #42406d;
    line-height: 1.5;
    margin-left: 15px;
}
